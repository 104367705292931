<template>
    <div class="progress-quiz-results">
        <progress-scorecard ref="scorecard" :title="scorecardTitle"
            :quiz-restart-link="quizRestartLink" :scores="scores" :grading="grading" :printable="printable"
        ></progress-scorecard>
    </div>
</template>
<script>
    import ProgressScorecard from "./ProgressScorecard.vue";
    export default {
        name: "ProgressQuizResults",
        components: {ProgressScorecard},
        props: {
            scorecardTitle: String,
            printable: Boolean
        },
        data() {
            return {
                grading: this.json.grading || [],
                scores: this.json.scores || [],
                quizRestartLink: this.json.restart_link || {},
            }
        },
        mounted() {
            jQuery(window).on('beforeunload', function() {
                return 'Results will not be saved. Are you sure you want to leave?';
            });
        }
    }
</script>

<style scoped lang="scss">
@import '../../css/_variables.scss';
.progress-quiz-results {
}
</style>
