var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", {
      staticClass: "question",
      attrs: { for: _vm.question.id },
      domProps: { innerHTML: _vm._s(_vm.question.question) }
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "answers" },
      _vm._l(_vm.answers, function(answer, index) {
        return _c("span", { key: index }, [
          _c("label", { staticClass: "answer", class: answer.class }, [
            _c("input", {
              staticClass: "sr-only",
              attrs: { name: _vm.question.id, type: "radio" },
              domProps: { value: index, checked: answer.checked },
              on: { change: _vm.changed, focus: _vm.focused }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "icon",
              attrs: { "aria-hidden": "true" },
              domProps: { innerHTML: _vm._s(answer.icon) }
            }),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(answer.label) } })
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }