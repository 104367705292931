var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress-todo-list vmt-component" },
    [
      _c(
        "h3",
        [
          _c("fa-icon", { attrs: { large: "", name: _vm.icon } }),
          _vm._v(
            "\n        Your " +
              _vm._s(_vm.listCategory ? _vm.listCategory.name : "") +
              " Learning Lineup"
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.listCategory
        ? _c("transition", { attrs: { name: "vmt-fade" } }, [
            _vm.hasCompletedList
              ? _c("p", [
                  _vm._v(
                    "You've completed your learning lineup! Create another one by clicking the “+” beside one of the categories below."
                  )
                ])
              : _c("p", [
                  _vm._v(
                    "Check the box beside an item once you've completed it. Uncheck if you want to re-do it."
                  )
                ])
          ])
        : _c("p", [
            _vm._v(
              "You haven’t set a learning lineup yet. Create one by clicking the “+” beside one of the categories below!"
            )
          ]),
      _vm._v(" "),
      _vm.listItems.length
        ? _c(
            "ul",
            { staticClass: "table-list" },
            _vm._l(_vm.listItems, function(item, index) {
              return _c(
                "li",
                { key: index, class: { completed: item.completed } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button-reset",
                      on: {
                        click: function($event) {
                          return _vm.toggleComplete(index)
                        }
                      }
                    },
                    [
                      _c(
                        "fa-icon",
                        {
                          staticClass: "test",
                          attrs: {
                            large: "",
                            name: item.completed ? "check-square-o" : "square-o"
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.completed ? "uncheck" : "check") +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "progress-todo-item",
                    _vm._b({}, "progress-todo-item", item, false)
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "visible",
              rawName: "v-visible",
              value: _vm.saving,
              expression: "saving"
            }
          ],
          staticClass: "spinner"
        },
        [
          _c("fa-icon", {
            staticClass: "progress-spin",
            attrs: { name: "spinner" }
          }),
          _vm._v(" Saving...")
        ],
        1
      ),
      _vm._v(" "),
      _c("progress-create-todo-list", {
        attrs: { category: _vm.createCategory },
        on: {
          created: _vm.createdList,
          "update:category": function($event) {
            _vm.createCategory = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }