<template>
    <modal :open.sync="modalOpen" :confirm-close="!!items.length">
        <template v-slot:title>
            <h3><fa-icon v-if="category.icon" large :name="category.icon"></fa-icon>
            Create Your {{ category.name }} Learning Lineup</h3>
        </template>
        <div class="instructions">
            <p>Drag suggested items into the list, or create your own custom items.</p>
            <p>We recommend a mixture of small, medium and large tasks to keep your list from getting overwhelming. You can add a maximum of 5 items but remember, you can always create a new list once you’ve completed this one!</p>
        </div>
        <div class="items-wrapper">
            <span v-visible="!items.length" class="drag-instructions"><fa-icon name="share" large></fa-icon> Drag suggested items here</span>
            <draggable ref="lineup" :list="items" :group="{ name: 'todo-items', pull: false, put: true }">
                <transition-group tag="ul" name="vmt-fade" class="table-list items">
                    <li v-for="(item, index) in items" :key="item.title">
                        <progress-todo-item :title="item.title" :size="item.size"></progress-todo-item>
                        <button type="button" class="button-icon" @click="removeItem(index)"><fa-icon name="remove">Remove</fa-icon></button>
                    </li>
                </transition-group>
            </draggable>
        </div>
        <transition name="vmt-fade"><p v-if="canSave"><button class="button-rounded" @click="save">Save Lineup</button></p></transition>
        <div v-show="suggestedItems.length">
            <h4>Learning Suggestions:</h4>
            <draggable v-if="suggestedItems" :list="suggestedItems" :group="{ name: 'todo-items', pull: canAdd, put: false }">
                <transition-group tag="ul" class="table-list suggested-items" name="vmt-fade-in">
                    <li v-for="item in suggestedItems" :key="item.title">
                        <progress-todo-item :title="item.title" :size="item.size"></progress-todo-item>
                    </li>
                </transition-group>
            </draggable>
        </div>
        <transition name="vmt-fade">
            <progress-create-todo-item @create="addItem" v-if="canAdd" />
        </transition>
    </modal>
</template>

<script>
    import ProgressTodoItem from "./ProgressTodoItem.vue";
    import ProgressCreateTodoItem from "./ProgressCreateTodoItem.vue";

    export default {
        name: "ProgressCreateTodoList",
        components: {ProgressCreateTodoItem, ProgressTodoItem},
        props: {
            category: Object
        },
        data() {
            return {
                suggestedItems: [],
                items: [],
                minItems: 1,
                maxItems: 5,
            }
        },
        watch: {
            category(newValue) {
                if (newValue) {
                    this.fetchSuggestedItems(newValue);
                }
                this.$set(this, 'items', []);
            }
        },
        computed: {
            modalOpen: {
                get() {
                    return !!this.category;
                },
                set(value) {
                    if (!value) {
                        this.close()
                    }
                }
            },
            canSave() {
                return this.items.length >= this.minItems;
            },
            canAdd() {
                return this.items.length < this.maxItems;
            }
        },
        methods: {
            addItem(newItem) {
                this.$scrollTo(this.$refs.lineup, {
                    container: this.$el,
                    onDone: function() {
                        this.items.push(newItem);
                    }.bind(this)
                });
            },
            removeItem(index) {
                const removed = this.items.splice(index, 1)[0];
                if (removed.id) {
                    this.suggestedItems.push(removed);
                }
            },
            close() {
                this.$emit('update:category', null)
            },
            save() {
                this.$emit('created', this.items, this.category);
                this.close();
            },
            fetchSuggestedItems(category) {
                jQuery.get(window.vmt.apiUrl + 'progress/todo-items-in-category/' + category.id)
                    .done((data) => {
                        this.suggestedItems = data;
                    });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../css/_variables.scss';
    .instructions {
        font-size: 1rem;
    }
    .table-list {
        li {
            cursor: grab;

            &[draggable=true] {
                opacity: 0.25;
            }
        }
    }
    .items-wrapper {
        position: relative;
        background-color: #ffffff;
    }
    .items {
        position: relative;
        min-height: 11.6875rem;
        transition: background-color 0.25s;
        &:empty {
            background-color: rgba(255,255,255, 0);
        }
        li {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }
    .drag-instructions {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $gray-medium;
        font-size: 1rem;
        text-align: center;

        .fa {
            transform: rotate(-45deg);
        }
    }
    .suggested-items:empty {
        border: 0;
    }
</style>
