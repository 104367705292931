<template>
    <span class="progress-todo-item" :class="{completed}">
        <span class="title">{{ title }} <span class="size">{{ sizeParts[0] }}<span class="sr-only">{{ sizeParts[1] }}</span></span></span>

        <a v-if="link && link.url" :href="link.url" target="_blank" class="link-with-icon">
            {{ link.title }}<fa-icon name="external-link"></fa-icon>
        </a>
    </span>
</template>

<script>
    export default {
        name: "ProgressTodoItem",
        props: {
            title: String,
            size: String,
            link: [Object, Array],
            completed: [Boolean, Number]
        },
        computed: {
            sizeParts() {
                return this.size.split('', 1)
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../../css/_variables.scss';
.progress-todo-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .title {
        display: flex;
        flex-grow: 1;
        margin-right: 2.5rem;
    }
    .size {
        margin-left: 0.5rem;
        display: inline-block;
        background-color: $ink;
        color: #ffffff;
        border-radius: 50%;
        text-transform: capitalize;
        height: 1.75em;
        width: 1.75em;
        text-align: center;
        font-family: "Raleway","Helvetica Neue",sans-serif;
        font-weight: 700;
        line-height: 1.75;
    }
    .link-with-icon {
        display: inline-block;
        font-size: 0.875em;
    }
    &.completed {
        text-decoration: line-through;

        .link-with-icon {
            color: inherit;
            pointer-events: none;
        }
    }
}
</style>
