<template>
    <div class="progress-scorecard vmt-component">
        <h3><i class="fa fa-2x fa-bar-chart"></i> {{ title }}</h3>
        <div v-if="scores.length">
            <ul class="table-list">
                <li v-for="(score, index) in scores" :key="index">
                    <progress-category-score v-bind="score" :grades="grades" v-on="$listeners">
                        <slot></slot>
                    </progress-category-score>
                </li>
            </ul>
            <div v-if="grading.length">
                <button class="button-link link-with-icon" @click="showGrading = !showGrading">
                    What do these letters mean?<i class="fa fa-question-circle"></i>
                </button>
                <transition name="vmt-fade">
                <ul class="grading" v-show="showGrading">
                    <li v-for="(grade, index) in grading" :key="index">
                        <span>{{ grade.letter }}</span> {{ grade.description }}
                    </li>
                </ul>
                </transition>
            </div>
            <div v-if="printable"><button class="button-link link-with-icon" @click="print">Print or save these results<i class="fa fa-print"></i></button></div>
            <div v-if="quizRestartLink.url"><a :href="quizRestartLink.url" class="link-with-icon">{{ quizRestartLink.title }}<i class="fa fa-arrow-circle-right"></i></a></div>
        </div>
        <div v-else>No scores yet! <a :href="quizRestartLink.url" class="link-with-icon">Take the Quiz<i class="fa fa-arrow-circle-right"></i></a></div>
    </div>
</template>

<script>
    import ProgressCategoryScore from "./ProgressCategoryScore.vue";
    export default {
        name: "ProgressScorecard",
        components: {ProgressCategoryScore},
        props: {
            title: String,
            scores: Array,
            quizRestartLink: Object,
            activeCategory: Object,
            grading: Array,
            printable: Boolean
        },
        data() {
            return {
                showGrading: false,
                grades: this.grading.map(g => g.letter)
            }
        },
        computed: {
            fullQuizUrl() {
                return this.quizUrl + window.location.search;
            }
        },
        methods: {
            print() {
                window.print();
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../css/_variables.scss';
    .progress-scorecard {
        .table-list {
            position: relative;
        }
        .grading span  {
            font-weight: bold;
            display: inline-block;
            background-color: #f5f5f5;
            width: 1.75em;
            margin: 0.25rem 0.5rem 0.25rem 0;
            text-align: center;
        }
    }
    @media print {
        .progress-scorecard {
            .link-with-icon {
                display: none;
            }
            .grading {
                display: block !important;
            }
            .table-list {
                max-width: 40rem;
                margin-left: auto;
                margin-right: auto;
                border: 0;
            }
        }

    }
</style>
