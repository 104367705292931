import ProgressQuiz from './components/ProgressQuiz.vue';
import ProgressDashboard from './components/ProgressDashboard.vue';
import ProgressQuizResults from './components/ProgressQuizResults.vue';

// Vue instances
(function() {
    const Vue = window.Vue || false;
    if (!Vue) {
        return;
    }
    Vue.component('progress-quiz', ProgressQuiz);
    Vue.component('progress-dashboard', ProgressDashboard);
    Vue.component('progress-quiz-results', ProgressQuizResults);
})();
