var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "vmt-component progress-quiz", attrs: { method: "post" } },
    [
      _c("div", { staticClass: "progress-bar" }, [
        _c("progress", {
          attrs: { max: "100" },
          domProps: { value: _vm.progress }
        }),
        _c("span", [_vm._v(_vm._s(_vm.progress) + "%")])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "questions" },
        [
          _c(
            "transition",
            { attrs: { name: "vmt-fade", mode: "out-in" } },
            [
              _c("progress-question", {
                key: _vm.currentQuestion.id,
                attrs: { question: _vm.currentQuestion },
                model: {
                  value: _vm.currentAnswer,
                  callback: function($$v) {
                    _vm.currentAnswer = $$v
                  },
                  expression: "currentAnswer"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.formData, function(value, key) {
        return _c("input", {
          key: key,
          attrs: { name: key, type: "hidden" },
          domProps: { value: value }
        })
      }),
      _vm._v(" "),
      _c("div", { staticClass: "step-nav" }, [
        _c(
          "button",
          {
            staticClass: "button-previous",
            attrs: { type: "button", disabled: _vm.currentStep === 0 },
            on: {
              click: function($event) {
                _vm.currentStep--
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa fa-2x fa-arrow-left",
              attrs: { "aria-hidden": "true" }
            }),
            _c("span", { staticClass: "sr-only" }, [_vm._v("Previous")])
          ]
        ),
        _vm._v(" "),
        _vm.currentStep + 1 < _vm.questions.length
          ? _c(
              "button",
              {
                staticClass: "button-next button-rounded",
                attrs: { type: "button", disabled: !_vm.hasAnswer },
                on: {
                  click: function($event) {
                    _vm.currentStep++
                  }
                }
              },
              [_vm._v("Next")]
            )
          : _c("input", {
              staticClass: "button-next button-rounded",
              attrs: {
                type: "submit",
                disabled: !_vm.hasAnswer,
                value: "Submit"
              }
            })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }