var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "progress-category-score",
      class: { "has-action": _vm.hasAction },
      attrs: { role: "button" },
      on: { click: _vm.buttonConfirm }
    },
    [
      _c(
        "span",
        { staticClass: "title" },
        [
          _vm.category.icon
            ? _c("fa-icon", { attrs: { name: _vm.category.icon, medium: "" } })
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.category.name) + "\n    ")
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAction
        ? _c("button", { staticClass: "button-icon" }, [_vm._t("default")], 2)
        : _vm._e(),
      _vm._v(" "),
      _c("progress-grade", {
        attrs: { grades: _vm.grades, current: _vm.grade }
      }),
      _vm._v(" "),
      _c(
        "confirm-dialog",
        {
          on: { confirm: _vm.buttonAction },
          model: {
            value: _vm.showConfirmation,
            callback: function($$v) {
              _vm.showConfirmation = $$v
            },
            expression: "showConfirmation"
          }
        },
        [_vm._v(_vm._s(_vm.actionConfirmation))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }