<template>
    <div>
    <label :for="question.id" v-html="question.question" class="question"></label>
    <div class="answers">
        <span v-for="(answer, index) in answers" :key="index">
            <label class="answer" :class="answer.class">
                <input class="sr-only" :name="question.id" type="radio" :value="index"
                       :checked="answer.checked" @change="changed" @focus="focused">
                <span v-html="answer.icon" class="icon" aria-hidden="true"></span>
                <span v-html="answer.label"></span>
            </label>
        </span>
    </div>
    </div>
</template>

<script>
    export default {
        name: "ProgressQuestion",
        props: ['question', 'value'],
        data() {
            return {
                focusedValue: null
            }
        },
        computed: {
            answers() {
                return this.question.answers.map((a, i) => {
                    a.checked = this.value === (i + '');
                    a.focused = this.focusedValue === (i + '');
                    a.class = { checked: a.checked, focused: a.focused };
                    return a;
                });
            },
        },
        methods: {
            changed(event) {
                this.$emit('input', event.target.value);
            },
            focused(event) {
                this.focusedValue = event.target.value;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../css/_variables.scss';
    .answers {
        display: inline-block;
        @media (min-width: 768px) {
            padding: 0 2rem;
        }
    }
    label {
        display: block;
    }
    .question {
        margin-bottom: 2rem;
        @media (min-width: 768px) {
            font-size: 1.25em;
        }
        >>> a {
            color: inherit;
            &:hover {
                color: $orange-light;
            }
        }
    }
    .answer {
        display: flex;
        align-items: center;
        font-size: 0.875em;
        margin-bottom: 1rem;
        padding: 0.75rem 1rem;
        background-color: $ivory;
        border-radius: 2rem;
        border: 0.25rem solid $ivory;
        cursor: pointer;
        @media (min-width: 768px) {
            font-size: 1em;
        }
        &:hover,
        &.focused,
        &.checked {
            border-color: $orange-light;
        }

        &.checked {
            background-color: $orange-light;
        }
        .icon {
            margin-right: 1rem;
        }
    }

</style>
