var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "progress-grade" },
    _vm._l(_vm.grades, function(letter, index) {
      return _c(
        "span",
        { key: index, staticClass: "segment", class: _vm.segmentClass(index) },
        [_c("span", { staticClass: "letter" }, [_vm._v(_vm._s(letter))])]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }