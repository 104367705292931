<template>
    <div class="progress-create-todo-item">
    <h4>Create Your Own:</h4>
    <form @submit.prevent="addItem">
        <div class="fields">
        <label class="title">Title <input type="text" required v-model="item.title"></label>
        <label class="size">Size <select v-model="item.size">
            <option value="small">Small</option>
            <option value="medium">Medium</option>
            <option value="large">Large</option>
        </select></label>
        <label class="link">Link <input type="text" v-model="item.link.url" placeholder="(optional)"></label>
        </div>
        <button type="submit" class="button-rounded button-small" :disabled="item.title === ''">Add To Lineup</button>
    </form>
    </div>
</template>

<script>
    export default {
        name: 'progress-create-todo-item',
        props: {
        },
        data() {
            return {
                item: {
                    title: '',
                    size: 'medium',
                    link: {
                        url: '',
                        title: 'Link'
                    }
                }
            }
        },
        methods: {
            addItem() {
                this.$emit('create', {...this.item, link: {...this.item.link}});
                this.item.title = '';
                this.item.size = 'medium';
                this.item.link.url = '';
            },
        }
    }
</script>

<style scoped lang="scss">
.progress-create-todo-item {
    .fields {
        display: flex;
        flex-flow: row wrap;
    }
    label {
        flex: 1 1 100%;
        display: block;
        margin-bottom: 1rem;
    }
    @media (min-width: 768px) {
        .title {
            flex-basis: 75%;
        }
        .size {
            flex-basis: 20%;
            margin-left: 1rem;
        }
    }
}
</style>
