<template>
    <span class="progress-category-score" role="button"
          :class="{'has-action': hasAction}"
          @click="buttonConfirm"
    >
        <span class="title">
            <fa-icon v-if="category.icon" :name="category.icon" medium></fa-icon>
        {{ category.name }}
        </span>
        <button class="button-icon" v-if="hasAction"><slot></slot></button>
        <progress-grade :grades="grades" :current="grade"></progress-grade>
        <confirm-dialog v-model="showConfirmation" @confirm="buttonAction">{{ actionConfirmation }}</confirm-dialog>
    </span>
</template>

<script>
    import ProgressGrade from "./ProgressGrade.vue";

    export default {
        name: "ProgressCategoryScore",
        components: {ProgressGrade},
        props: {
            category: Object,
            percentage: Number,
            grade: String,
            actionConfirmation: String,
            grades: Array
        },
        data() {
            return {
                showButton: false,
                showConfirmation: false,
                hasAction: !!this.$slots.default
            }
        },
        methods: {
            buttonConfirm(event) {
                if (this.actionConfirmation) {
                    this.showConfirmation = true;
                } else {
                    this.buttonAction(event);
                }

            },
            buttonAction() {
                this.showConfirmation = false;
                this.$emit('action', this.category);
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../../css/_variables.scss';
.progress-category-score {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    &.has-action {
        cursor: pointer;
    }

    .title {
        margin-right: 2rem;
        flex-basis: 25%;
        @media only screen and (max-width: 767px) {
            flex-basis: auto;
        }
        .fa {
            width: 1em;
            margin-right: 0.5rem;
            text-align: center;
        }
    }
    .button-icon {
        margin-left: 2rem;
        @media (min-width: 768px) {
            order: 1;
        }
    }
    @media print {
        .button-icon {
            display: none;
        }
        .title {
            flex-basis: 35%;
        }
    }

    .progress-grade {
        flex: 1 0 auto;
        margin-top: 0;

        @media only screen and (max-width: 767px) {
            margin-top: 1rem;
            flex-basis: 100%;
        }
    }

}
</style>
