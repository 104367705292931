<template>
    <form method="post" class="vmt-component progress-quiz">
        <div class="progress-bar"><progress max="100" :value="progress"></progress><span>{{ progress }}%</span></div>
        <div class="questions">
        <transition name="vmt-fade" mode="out-in">
            <progress-question :question="currentQuestion" v-model="currentAnswer" :key="currentQuestion.id">
            </progress-question>
        </transition>
        </div>
        <input v-for="(value, key) in formData" :key="key" :name="key" :value="value" type="hidden" />
        <div class="step-nav">
            <button type="button" class="button-previous" :disabled="currentStep === 0" @click="currentStep--"><i class="fa fa-2x fa-arrow-left" aria-hidden="true"></i><span class="sr-only">Previous</span></button>
            <button type="button" class="button-next button-rounded" v-if="currentStep + 1 < questions.length" :disabled="!hasAnswer" @click="currentStep++">Next</button>
            <input v-else  class="button-next button-rounded" type="submit" :disabled="!hasAnswer" value="Submit" />
        </div>
    </form>
</template>

<script>
    import ProgressQuestion from "./ProgressQuestion.vue";
    export default {
        name: "ProgressQuiz",
        components: {ProgressQuestion},
        props: {
            id: String
        },
        data() {
            return {
                currentStep: 0,
                questions: this.json,
                formData: Object.fromEntries(this.json.map(q => [q.id, null])),
            }
        },
        computed: {
            currentQuestion() {
                return this.questions[this.currentStep];
            },
            currentAnswer: {
                get() {
                    return this.formData[this.currentQuestion.id];
                },
                set(newValue) {
                    this.formData[this.currentQuestion.id] = newValue;
                }
            },
            hasAnswer() {
                return this.currentAnswer !== null;
            },
            progress() {
                const answered = this.currentStep + (this.hasAnswer + 0) ;
                return Math.round(answered / this.questions.length * 100);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../css/_variables.scss';
    .progress-quiz {
        max-width: 40rem;
        margin: 1rem 0;
        padding: 1rem;
        background-image: linear-gradient(to bottom, $coral, $orange);
        border-radius: 1rem;
        box-shadow: 4px 4px 8px 0 $gray-medium;
        @media (min-width: 768px) {
            margin: 2rem auto;
            padding: 2rem;
        }

        .questions {
            margin: 2rem 0;
        }
        .progress-bar {
            display: flex;
            align-items: center;

            progress {
                appearance: none;
                border: none;
                flex: 1 0 auto;
                height: 1.25rem;
                margin-right: 2rem;
                &::-webkit-progress-bar {
                    background-color: $ivory;
                }
                &::-webkit-progress-value {
                    background-color: $orange;
                    background-image:
                        linear-gradient(45deg,
                            transparent 33%, $orange-light 33%,
                            $orange-light 66%, transparent 66%);
                    background-size: 2.5rem 1.25rem;
                }
                &::-moz-progress-bar {
                    background-color: $coral;
                }
            }
            span {
                flex-basis: 20%;
            }
        }
        .step-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #ffffff;
            padding-top: 2rem;

            .button-previous {
                border-radius: 50%;
                width: 3rem;
                height: 3rem;
                padding: 0;
            }
        }


    }
</style>
