<template>
    <span class="progress-grade">
        <span v-for="(letter, index) in grades" :key="index" class="segment" :class="segmentClass(index)">
            <span class="letter">{{ letter }}</span>
        </span>
    </span>
</template>

<script>
    export default {
        name: "ProgressGrade",
        props: {
            grades: Array,
            current: String
        },
        data() {
            return {
                currentIndex: this.grades.indexOf(this.current)
            }
        },
        methods: {
            segmentClass(index) {
                return {
                    current: this.currentIndex === index,
                    active: this.currentIndex >= index && this.currentIndex > 0
                }
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../../css/_variables.scss';
.progress-grade {
    display: flex;
    align-items: center;

    .segment {
        color: $ink-light;
        background-color: currentColor;
        height: 2em;
        border-radius: 0 1em 1em 0;
        margin-right: -1em;
        flex: 1 0 auto;
        text-align: right;

        &:first-child {
            flex: 0 1 auto;
            border-radius: 1em;
        }

        &:last-child {
            flex: 0 1 auto;
            margin-right: 0;
            padding-left: 3rem;
            .letter {
                background-color: transparent;
                color: #ffffff;
                border-color: transparent;
                opacity: 0.25;
            }
        }

        &.active {
            color: $orange;

            .letter {
                opacity: 1;
            }
        }

        &.current {
            color: $coral;
            background-image: linear-gradient(to right, $orange, $coral);
        }
    }

    .letter {
        position: relative;
        display: inline-block;
        background-color: #ffffff;
        color: inherit;
        border: 2px solid currentColor;
        border-radius: 50%;
        text-transform: capitalize;
        height: 2em;
        width: 2em;
        text-align: center;
        font-family: "Raleway","Helvetica Neue",sans-serif;
        font-weight: 700;
        line-height: 1.75;
    }
}

@media print {
    .progress-grade {
        .letter {
            top: -1em;
            right: -1em;
            -webkit-print-color-adjust: exact;
        }
        .segment {
            background: none !important;
            border: 19px solid transparent; /* round up from 1em */

            &:last-child {
                padding-left: 1rem;
                border-color: $gray-light;
                .letter {
                    opacity: 1;

                }
                &:not(.active) .letter {
                    color: inherit;
                }
            }

            &.active,
            &.current {
                border-color: currentColor;

                &:first-child {
                    width: 2em;
                    .letter {
                        right: 1em;
                    }
                }
            }
        }
    }
}
</style>
