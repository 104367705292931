<template>
    <div class="progress-todo-list vmt-component">
        <h3>
            <fa-icon large :name="icon"></fa-icon>
            Your {{ listCategory ? listCategory.name : '' }} Learning Lineup</h3>

        <transition name="vmt-fade" v-if="listCategory">
        <p v-if="hasCompletedList">You've completed your learning lineup! Create another one by clicking the “+” beside one of the categories below.</p>
        <p v-else>Check the box beside an item once you've completed it. Uncheck if you want to re-do it.</p>
        </transition>
        <p v-else>You haven’t set a learning lineup yet. Create one by clicking the “+” beside one of the categories below!</p>

        <ul v-if="listItems.length" class="table-list">
            <li v-for="(item, index) in listItems" :key="index" :class="{completed: item.completed}">
                <button class="button-reset" @click="toggleComplete(index)">
                    <fa-icon class="test" large :name="item.completed ? 'check-square-o' : 'square-o'">
                        {{ item.completed ? 'uncheck' : 'check' }}
                    </fa-icon>
                </button><progress-todo-item v-bind="item"></progress-todo-item>
            </li>
        </ul>
        <span v-visible="saving" class="spinner"><fa-icon name="spinner" class="progress-spin"></fa-icon> Saving...</span>
        <progress-create-todo-list @created="createdList" :category.sync="createCategory">
        </progress-create-todo-list>

    </div>
</template>

<script>
    import ProgressCreateTodoList from "./ProgressCreateTodoList.vue";
    import ProgressTodoItem from "./ProgressTodoItem.vue";
    export default {
        name: "ProgressTodoList",
        components: {ProgressTodoItem, ProgressCreateTodoList},
        props: {
            list: Object,
            newCategory: Object,
            value: false
        },
        data() {
            return {
                listItems: this.list.items,
                listCategory: this.list.category,
                saving: false,
            }
        },
        computed: {
            icon() {
                return this.listCategory && this.listCategory.icon ?
                    this.listCategory.icon : 'list-ul';
            },
            hasCompletedList() {
                return this.listItems.length && (this.listItems.filter(i => !i.completed).length === 0);
            },
            createCategory: {
                get() {
                    return this.newCategory;
                },
                set(value) {
                    this.$emit('update:new-category', value);
                }
            }
        },
        watch: {
            listItems(newValue) {
                if (!(newValue && newValue.length)) {
                    return;
                }
                this.saveList();
            }
        },
        methods: {
            toggleComplete(index) {
                const item = this.listItems[index];
                item.completed = !item.completed;
                this.saveList();
            },
            createdList(items, category) {
                this.$set(this, 'listCategory', category);
                this.$set(this, 'listItems', items);
                this.$emit('update:list', {items, category});
                this.$scrollTo(this.$el);
            },
            saveList() {
                this.saving = true;
                jQuery.post(window.vmt.apiUrl + 'progress/todo-list', {
                    category_id: this.listCategory.id,
                    items: this.listItems.map((item) => {
                        item.completed = !!item.completed + 0;
                        return item;
                    })
                }).done((data) => {
                    this.saving = false;
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../css/_variables.scss';
    .progress-todo-list {
        li {
            transition: opacity 0.25s ease-in-out;
            display: flex;
            align-items: baseline;

            > :last-child {
                flex-grow: 1;
            }
        }
        .completed {
            opacity: 0.25;
        }
        .button-reset {
            width: 2.25rem;
            margin-right: 0.5rem;
            color: inherit;
        }
        .spinner {
            display: block;
            margin: -1rem 0;
        }
    }
</style>
