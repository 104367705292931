var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress-dashboard" },
    [
      _vm.scores.length
        ? _c("progress-todo-list", {
            ref: "todoList",
            attrs: { list: _vm.todoList, "new-category": _vm.newCategory },
            on: {
              "update:list": function($event) {
                _vm.todoList = $event
              },
              "update:newCategory": function($event) {
                _vm.newCategory = $event
              },
              "update:new-category": function($event) {
                _vm.newCategory = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "progress-scorecard",
        {
          ref: "scorecard",
          attrs: {
            title: _vm.scorecardTitle,
            "quiz-restart-link": _vm.quizRestartLink,
            scores: _vm.scores,
            "active-category": _vm.activeCategory,
            grading: _vm.grading
          },
          on: { action: _vm.createTodoList }
        },
        [
          _c("fa-icon", { attrs: { name: "plus" } }),
          _c("fa-icon", { attrs: { name: "list-ul" } }, [
            _vm._v("Create Learning Lineup")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }