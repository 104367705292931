<template>
    <div class="progress-dashboard">
        <progress-todo-list ref="todoList"
            v-if="scores.length" :list.sync="todoList" :new-category.sync="newCategory"
        ></progress-todo-list>
        <progress-scorecard ref="scorecard" :title="scorecardTitle"
            :quiz-restart-link="quizRestartLink" :scores="scores" :active-category="activeCategory"
            :grading="grading" @action="createTodoList"
        >
            <fa-icon name="plus"></fa-icon><fa-icon name="list-ul">Create Learning Lineup</fa-icon>
        </progress-scorecard>
    </div>
</template>
<script>
    import ProgressScorecard from "./ProgressScorecard.vue";
    import ProgressTodoList from "./ProgressTodoList.vue";
    export default {
        name: "ProgressDashboard",
        components: {ProgressScorecard, ProgressTodoList},
        props: {
            scorecardTitle: String
        },
        data() {
            return {
                grading: this.json.grading || [],
                todoList: this.json.todo_list || {category: null, items: []},
                newCategory: null,
                showCreate: false,
                quizRestartLink: this.json.restart_link || {}
            }
        },
        computed: {
            activeCategory() {
                return this.todoList.category;
            },
            scores() {
                const scores = this.json.scores || [];
                return scores.map((score) => {
                    if (this.activeCategory) {
                        score.actionConfirmation = 'Are you sure you want to replace the current Learning Lineup with a new one?';
                        score.isActive = score.category.id === this.activeCategory.id;
                    }
                    return score;
                });
            }
        },
        methods: {
            createTodoList(category) {
                this.$set(this, 'newCategory', category);
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../../css/_variables.scss';
.progress-dashboard {
    display: flex;
    flex-flow: row wrap;

    > * {
        width: 100%;
    }
    > :first-child {
        margin: 0 0 1rem 0;
        padding: 0 0 1rem 0;
        border: solid $coral;
        border-width: 0 0 2px 0;
    }
}
</style>
