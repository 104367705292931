var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress-scorecard vmt-component" }, [
    _c("h3", [
      _c("i", { staticClass: "fa fa-2x fa-bar-chart" }),
      _vm._v(" " + _vm._s(_vm.title))
    ]),
    _vm._v(" "),
    _vm.scores.length
      ? _c("div", [
          _c(
            "ul",
            { staticClass: "table-list" },
            _vm._l(_vm.scores, function(score, index) {
              return _c(
                "li",
                { key: index },
                [
                  _c(
                    "progress-category-score",
                    _vm._g(
                      _vm._b(
                        { attrs: { grades: _vm.grades } },
                        "progress-category-score",
                        score,
                        false
                      ),
                      _vm.$listeners
                    ),
                    [_vm._t("default")],
                    2
                  )
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.grading.length
            ? _c(
                "div",
                [
                  _c(
                    "button",
                    {
                      staticClass: "button-link link-with-icon",
                      on: {
                        click: function($event) {
                          _vm.showGrading = !_vm.showGrading
                        }
                      }
                    },
                    [
                      _vm._v("\n                What do these letters mean?"),
                      _c("i", { staticClass: "fa fa-question-circle" })
                    ]
                  ),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "vmt-fade" } }, [
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showGrading,
                            expression: "showGrading"
                          }
                        ],
                        staticClass: "grading"
                      },
                      _vm._l(_vm.grading, function(grade, index) {
                        return _c("li", { key: index }, [
                          _c("span", [_vm._v(_vm._s(grade.letter))]),
                          _vm._v(
                            " " +
                              _vm._s(grade.description) +
                              "\n                "
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.printable
            ? _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "button-link link-with-icon",
                    on: { click: _vm.print }
                  },
                  [
                    _vm._v("Print or save these results"),
                    _c("i", { staticClass: "fa fa-print" })
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.quizRestartLink.url
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "link-with-icon",
                    attrs: { href: _vm.quizRestartLink.url }
                  },
                  [
                    _vm._v(_vm._s(_vm.quizRestartLink.title)),
                    _c("i", { staticClass: "fa fa-arrow-circle-right" })
                  ]
                )
              ])
            : _vm._e()
        ])
      : _c("div", [
          _vm._v("No scores yet! "),
          _c(
            "a",
            {
              staticClass: "link-with-icon",
              attrs: { href: _vm.quizRestartLink.url }
            },
            [
              _vm._v("Take the Quiz"),
              _c("i", { staticClass: "fa fa-arrow-circle-right" })
            ]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }