var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress-create-todo-item" }, [
    _c("h4", [_vm._v("Create Your Own:")]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.addItem.apply(null, arguments)
          }
        }
      },
      [
        _c("div", { staticClass: "fields" }, [
          _c("label", { staticClass: "title" }, [
            _vm._v("Title "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.title,
                  expression: "item.title"
                }
              ],
              attrs: { type: "text", required: "" },
              domProps: { value: _vm.item.title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "title", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "size" }, [
            _vm._v("Size "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.size,
                    expression: "item.size"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.item,
                      "size",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { attrs: { value: "small" } }, [_vm._v("Small")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "medium" } }, [
                  _vm._v("Medium")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "large" } }, [_vm._v("Large")])
              ]
            )
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "link" }, [
            _vm._v("Link "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.link.url,
                  expression: "item.link.url"
                }
              ],
              attrs: { type: "text", placeholder: "(optional)" },
              domProps: { value: _vm.item.link.url },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item.link, "url", $event.target.value)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button-rounded button-small",
            attrs: { type: "submit", disabled: _vm.item.title === "" }
          },
          [_vm._v("Add To Lineup")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }