var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "progress-todo-item", class: { completed: _vm.completed } },
    [
      _c("span", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.title) + " "),
        _c("span", { staticClass: "size" }, [
          _vm._v(_vm._s(_vm.sizeParts[0])),
          _c("span", { staticClass: "sr-only" }, [
            _vm._v(_vm._s(_vm.sizeParts[1]))
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.link && _vm.link.url
        ? _c(
            "a",
            {
              staticClass: "link-with-icon",
              attrs: { href: _vm.link.url, target: "_blank" }
            },
            [
              _vm._v("\n        " + _vm._s(_vm.link.title)),
              _c("fa-icon", { attrs: { name: "external-link" } })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }