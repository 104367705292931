var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress-quiz-results" },
    [
      _c("progress-scorecard", {
        ref: "scorecard",
        attrs: {
          title: _vm.scorecardTitle,
          "quiz-restart-link": _vm.quizRestartLink,
          scores: _vm.scores,
          grading: _vm.grading,
          printable: _vm.printable
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }