var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { open: _vm.modalOpen, "confirm-close": !!_vm.items.length },
      on: {
        "update:open": function($event) {
          _vm.modalOpen = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c(
                "h3",
                [
                  _vm.category.icon
                    ? _c("fa-icon", {
                        attrs: { large: "", name: _vm.category.icon }
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n        Create Your " +
                      _vm._s(_vm.category.name) +
                      " Learning Lineup"
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "instructions" }, [
        _c("p", [
          _vm._v(
            "Drag suggested items into the list, or create your own custom items."
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "We recommend a mixture of small, medium and large tasks to keep your list from getting overwhelming. You can add a maximum of 5 items but remember, you can always create a new list once you’ve completed this one!"
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "items-wrapper" },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "visible",
                  rawName: "v-visible",
                  value: !_vm.items.length,
                  expression: "!items.length"
                }
              ],
              staticClass: "drag-instructions"
            },
            [
              _c("fa-icon", { attrs: { name: "share", large: "" } }),
              _vm._v(" Drag suggested items here")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "draggable",
            {
              ref: "lineup",
              attrs: {
                list: _vm.items,
                group: { name: "todo-items", pull: false, put: true }
              }
            },
            [
              _c(
                "transition-group",
                {
                  staticClass: "table-list items",
                  attrs: { tag: "ul", name: "vmt-fade" }
                },
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "li",
                    { key: item.title },
                    [
                      _c("progress-todo-item", {
                        attrs: { title: item.title, size: item.size }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button-icon",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.removeItem(index)
                            }
                          }
                        },
                        [
                          _c("fa-icon", { attrs: { name: "remove" } }, [
                            _vm._v("Remove")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "vmt-fade" } }, [
        _vm.canSave
          ? _c("p", [
              _c(
                "button",
                { staticClass: "button-rounded", on: { click: _vm.save } },
                [_vm._v("Save Lineup")]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.suggestedItems.length,
              expression: "suggestedItems.length"
            }
          ]
        },
        [
          _c("h4", [_vm._v("Learning Suggestions:")]),
          _vm._v(" "),
          _vm.suggestedItems
            ? _c(
                "draggable",
                {
                  attrs: {
                    list: _vm.suggestedItems,
                    group: { name: "todo-items", pull: _vm.canAdd, put: false }
                  }
                },
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "table-list suggested-items",
                      attrs: { tag: "ul", name: "vmt-fade-in" }
                    },
                    _vm._l(_vm.suggestedItems, function(item) {
                      return _c(
                        "li",
                        { key: item.title },
                        [
                          _c("progress-todo-item", {
                            attrs: { title: item.title, size: item.size }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "vmt-fade" } },
        [
          _vm.canAdd
            ? _c("progress-create-todo-item", { on: { create: _vm.addItem } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }